import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

export const ConfirmClearModal = ({ isOpen = false, onConfirm = () => {}, onClose = () => {} }) => {
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header>
        <h4 className={`h2 mb-0`}>Clear shopping cart</h4>
        <button type="button" className="close px-2" aria-label="Close" onClick={onClose}>
          <span className="uil uil-times" aria-hidden="true" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className={`mb-0`}>Are you sure? All items will be removed from the shopping cart.</div>
      </Modal.Body>
      <Modal.Footer>
        <div className={`d-flex`}>
          <button type="button" className="btn-tg-secondary mr-2" onClick={onClose}>Cancel</button>
          <button type="button" className="btn-tg-sale" onClick={onConfirm}>Confirm</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmClearModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}

export default ConfirmClearModal;
