import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from '../../Common/PreviewCompatibleImage';
import { formatPrice } from '../../../helpers/format-price.helper';
import './cart-item.scss'

export const CartItem = ({cartItem, onDeleteCallback = () => {}}) => {
  function deleteItem() {
    if (cartItem && cartItem.id) {
      onDeleteCallback(cartItem.id);
    }
  }

  return (
    <section className={`d-flex w-100 py-3 p-md-3 cart-item`}>
      <button
        className="btn-tg-icon-secondary btn-tg-icon-lg align-self-center mr-2 mr-md-3 flex-shrink-0"
        type="button"
        title="delete"
        onClick={deleteItem}
      >
        <span className="uil uil-times"/>
      </button>

      <div className="thumbnail-wrap flex-shrink-0 mr-2 mr-sm-3 mr-lg-32">
        <div className="thumbnail">
          <div className="thumbnail-container">
            <div className="thumbnail-wrapper">
              <PreviewCompatibleImage
                imageInfo={{
                  image: cartItem.thumbnail,
                  alt: cartItem.title,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row flex-grow-1 pr-lg-2 justify-content-lg-between">
        <h3 className="h6 mb-1 cart-item-title mr-md-3 multi-line-text-truncate line-2">
          { cartItem.title }
        </h3>
        <p className="monospace flex-shrink-0 cart-item-price mb-0">
          $ {formatPrice( cartItem.price ) }
        </p>
      </div>
    </section>
  )
}

CartItem.propTypes = {
  cartItem: PropTypes.object.isRequired,
  onDeleteCallback: PropTypes.func
}

export default CartItem;
