import React from 'react';
import './purchase-shopping-cart.scss';
import PropTypes from 'prop-types';
import { formatPrice } from '../../../helpers/format-price.helper';
import shoppingCartService from '../../../services/shopping-cart.service';
import hasWindow from '../../../constants/has-window.const';

export const PurchaseShoppingCart = ({
  itemText = 'Items',
  totalCount = 0,
  totalPrice = 0,
}) => {
  function goToCheckoutPage() {
    if (shoppingCartService.hasToken() && hasWindow) {
      const token = shoppingCartService.getCartToken();
      const url = `${process.env.TOTALLY_LESSONS_URL}/buy?type=cart&token=${token}`;
      window.location.assign(url);
    }
  }

  function isProducts() {
    return totalCount && totalCount > 0;
  }

  return (
    <div className="purchase-cart p-3 position-relative">
      <div className="p-2 d-flex justify-content-between">
        <span>{itemText} ({totalCount})</span>
        <span className="monospace font-weight-medium">$ {formatPrice(totalPrice) || '0.00'}</span>
      </div>

      <hr className="divider divider-light-grey my-2 my-lg-3"/>

      <div className="p-2 d-flex justify-content-between">
        <span className="font-weight-medium small subtotal-title">Subtotal:</span>
        <span className="monospace font-weight-bold subtotal-price">
          $ {formatPrice(totalPrice) || '0.00'}
        </span>
      </div>
      <div className="mx-2 mb-2 mb-lg-3 mt-2 mt-lg-4 text-center">
        <button
          type="button"
          className="btn-tg-sale btn-checkout w-100"
          disabled={!isProducts()}
          onClick={goToCheckoutPage}
        >
          Go to checkout
        </button>
      </div>
    </div>
  );
}

PurchaseShoppingCart.propTypes = {
  itemText: PropTypes.string,
  totalCount: PropTypes.number,
  totalPrice: PropTypes.number,
  isProducts: PropTypes.bool,
}

export default PurchaseShoppingCart;
