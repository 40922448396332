import React from 'react'
import Layout from '../components/Layout/Layout'
import ShoppingCart from '../components/ShoppingCart/ShoppingCart';

const ShoppingCartPage = () => {
  return (
    <Layout title="Shopping cart">
      <ShoppingCart />
    </Layout>
  )
};

export default ShoppingCartPage;
