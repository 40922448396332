import React, { useState, useEffect } from 'react';
import CartItem from './CartItem/CartItem';
import shoppingCartService from '../../services/shopping-cart.service';
import { isInternalServerError } from '../../services/axios.service';
import { handleError, handleInternalServerError } from '../../helpers/error-handler.helper';
import { ShoppingCartList } from '../../Models/ShoppingCartList';
import Loader from '../Common/Loader/Loader';
import * as HttpStatus from 'http-status-codes';
import ConfirmClearModal from './ConfirmClearModal';
import { Link } from 'gatsby';
import PurchaseShoppingCart from './PurchsaseShoppingCart/PurchaseShoppingCart';

export const ShoppingCart = () => {
  const [shoppingCartList, setShoppingCartList] = useState(() => new ShoppingCartList({}));
  const [isCartLoaded, setIsCartLoaded] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  useEffect(() => {
    loadCartList();
  }, [])

  function isProducts() {
    return (
      shoppingCartList &&
      Array.isArray(shoppingCartList.products) &&
      shoppingCartList.products.length > 0
    );
  }

  function getItemsText() {
    if (isProducts() && shoppingCartList.products.length > 1) {
      return 'Items';
    }
    return 'Item';
  }

  function loadCartList() {
    setIsLoader(true);
    shoppingCartService.getCartItems()
      .then((cartList) => {
        setShoppingCartList(cartList);
        setIsCartLoaded(true);
      })
      .catch((error) => {
        if (isInternalServerError()) {
          handleInternalServerError(error);
        }
        return new ShoppingCartList({});
      })
      .finally(() => {
        setIsLoader(false);
      });
  }

  function deleteItem(id) {
    setIsLoader(true);
    shoppingCartService.deleteOne(id)
      .then(() => {
        loadCartList();
        shoppingCartService.updateCount();
      })
      .catch(err => {
        handleError(err, [HttpStatus.NOT_FOUND, HttpStatus.BAD_REQUEST])
      })
      .finally(() => {
      setIsLoader(false);
    });
  }

  const closeConfirmModal = () => {
    setIsConfirmModal(false);
  }

  const onConfirmDeleteAll = () => {
    setIsLoader(true);
    closeConfirmModal();
    shoppingCartService.deleteAll()
      .then(() => {
        shoppingCartService.removeCartToken();
        shoppingCartService.updateCount();
        loadCartList();
      })
      .catch((err) => {
        handleError(err, [HttpStatus.BAD_REQUEST])
      })
      .finally(() =>    setIsLoader(false));
  }

  return (
    <section className="mt-5 shopping-cart container">
      {isLoader && (<Loader hasBackground={true} isFixed={true}/>)}

      <div className="pb-3 border-bottom-light-gray d-flex flex-column flex-sm-row">
        <div className="d-flex align-items-center mb-3 mb-sm-0">
          <h2 className="h3 text-uppercase mb-0">Shopping cart</h2>
          <span className="ml-2">({ shoppingCartList.totalCount || 0} { getItemsText() })</span>
        </div>

        <div className="d-flex align-items-center ml-sm-auto mr-sm-3">
          <Link className="btn-tg-link mr-4 mr-md-6" to={'/specials'}>
            Continue shopping
          </Link>

          <button
            type="button"
            className="btn btn-tg-link"
            onClick={() => setIsConfirmModal(true)}
            disabled={!isProducts()}
          >
            Clear all
          </button>
        </div>
      </div>

      <div className="mt-4 mt-lg-6 row">
        <div className="col col-12 col-lg-4 order-lg-1 mt-lg-3 mb-3">
          <PurchaseShoppingCart
            isProducts={isProducts()}
            itemText={getItemsText()}
            totalCount={shoppingCartList.totalCount || 0}
            totalPrice={shoppingCartList.totalPrice || 0}
          />
        </div>
        <div className="col col-12 col-lg-8">
          {
            isProducts() && isCartLoaded && (
              <ul className={`list-unstyled`}>
                {
                  shoppingCartList.products.map((item, i) => (
                    <li key={i} className={`border-divider`}>
                      <CartItem cartItem={item} onDeleteCallback={deleteItem}/>
                    </li>
                  ))
                }
              </ul>
            )
          }
          {
            !isProducts() && isCartLoaded && (
              <p className="font-weight-bold text-center description font-size-base">
                You don't have any items in your cart.
              </p>
            )
          }
        </div>
      </div>
      <ConfirmClearModal
        isOpen={isConfirmModal}
        onConfirm={onConfirmDeleteAll}
        onClose={closeConfirmModal}
      />
    </section>
  )
}

export default ShoppingCart;
