import React from 'react';
import PropTypes from 'prop-types';
import './loader.scss';

const Loader = ({hasBackground, isFixed}) => {
  function getBackground() {
    return hasBackground ? 'background-visible' : '';
  }

  return (
    <div className={`custom-loader-background ${getBackground()} ${isFixed ? 'loader-fixed' : ''}`}>
      <div className="custom-loader">
        <div/><div/><div/><div/>
      </div>
    </div>
  );
};

Loader.propTypes = {
  hasBackground: PropTypes.bool,
  isFixed: PropTypes.bool,
};

export default Loader;
